export const environment = {
   blogger: 'AIzaSyCww5b1x2z_nSzPYupoiv2o_8NTf8UPx2M',
   production: 'true',
   firebase: {
     apiKey: 'AIzaSyDZfxtQKdeQsHuLBPKKcQw_cxxZvOQOEd8',
     authDomain: 'pdsa-oskee.firebaseapp.com',
     databaseURL: 'https://pdsa-oskee.firebaseio.com',
     projectId: 'pdsa-oskee',
     storageBucket: 'pdsa-oskee.appspot.com',
     messagingSenderId: '827195330308',
     appId: '1:827195330308:web:5cf8ccf8fcff4645d58a51',
     measurementId: 'G-9NYP9FDKMY',
   },
   sendGrid: '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',
   web: {
    client_id: '312691641900-ec87um3es8joumjt3kn8477mk1u538m3.apps.googleusercontent.com',
    project_id: 'chc-resource-gui-1599343822636',
    auth_uri: 'https://accounts.google.com/o/oauth2/auth',
    token_uri: 'https://oauth2.googleapis.com/token',
    auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
    client_secret: 'vRGdTHgYaCTaxpRdvEu2AJhv',
    javascript_origins: [
      "http://localhost:4200"
    ],
    api_key: 'AIzaSyCHsy8WG0lEGrLF5aI7gvdEv2pS-dXb8j0',
  }
};
