export enum AccessType {
  STATEMENTS_BATCH_UPLOADED = 'STATEMENTS_BATCH_UPLOADED',
  STATEMENTS_LOAD_FILE_TEST= 'STATEMENTS_LOAD_FILE_TEST',
  STATEMENTS_LOAD_FILE_LIVE= 'STATEMENTS_LOAD_FILE_LIVE',
  STATEMENTS_CREATE_STATEMENT_TEST = 'STATEMENTS_CREATE_STATEMENT_TEST',
  STATEMENTS_VIEW_STATEMENT_TEST = 'STATEMENTS_VIEW_STATEMENT_TEST',
  STATEMENTS_CREATE_STATEMENT_LIVE = 'STATEMENTS_CREATE_STATEMENT_LIVE',
  STATEMENTS_VIEW_STATEMENT_LIVE = 'STATEMENTS_VIEW_STATEMENT_LIVE',
  STATEMENTS_HISTORY_SEARCH = 'STATEMENTS_HISTORY_SEARCH',
  STATEMENTS_HISTORY_LOAD_LOB = 'STATEMENTS_HISTORY_LOAD_LOB',
  STATEMENTS_HISTORY_OPEN_LOB = 'STATEMENTS_HISTORY_OPEN_LOB',
  STATEMENTS_HISTORY_BATCH_LOAD = 'STATEMENTS_HISTORY_BATCH_LOAD',
}

export enum AccessTitleType {
  STATEMENTS_LOAD_FILE_TEST= 'Batch Run Test',
  STATEMENTS_LOAD_FILE_LIVE= 'Batch Run Live',
}

export interface AccessObj {
  created: any,
  userId: string,
  what: AccessType,
  patientId?: string,
  ltrId?: string,
  filename?: string,
}
